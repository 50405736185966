import React from "react"
import BlackButton from "../../components/Atomic/button/BlackButton"

export default function index() {
  return (
    <div class="basic-wrapper grid place-items-center h-screen">
      <BlackButton
        text={"自動で切り替わらない場合はこちらを押してください"}
        link={"/recipe/page/1"}
      />
    </div>
  )
}
